<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row class="justify-content-end">
        <!-- Search -->
        <!-- <b-col
          cols="12"
          md="8"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              @keyup="GetTotal(searchQuery)"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col> -->

        <!-- Total -->
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-right justify-content-end mb-1 mb-md-0"
        >
          <b-button
            variant="outline-success"
            pill
          >
            Total : {{formatCurrency(totalList, false)}}
          </b-button>
        </b-col>

        <!-- Entry -->
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            variant="primary"
            :to="{ name: 'apps-transactions-purchase-payment-per-voucher-add'}"
          >
            <feather-icon icon="PlusIcon" /> {{ $t('apps.transactions.purchase.payment.actions.create') }}
          </b-button>
        </b-col>
      </b-row>
      <!-- <div class="demo-inline-spacing">
        <b-button
            variant="outline-success"
            pill
          >
            Total : {{formatCurrency(totalList, false)}}
          </b-button>
      </div> -->
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #head()="{ field }">
        <table-search-column
          :field="field"
          :fields="tableColumns"
          :focused-field="focusedSearchField"
          @set-focused-field="(value) => focusedSearchField = value"
          @search-data="(params) => searchData(params)"
        />
      </template>

      <!-- Column: code -->
      <template #cell(code)="{item}">
        <b-link
          :to="{ name: 'apps-transactions-purchase-payment-detail', params: { id: item.id }}"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            {{ item.code }}
          </span>
        </b-link>
      </template>

      <!-- Column: Image File -->
      <template #cell(image)="{item}">
        <div v-if="item.name_file !== null">
        <b-link
          :to="{ name: 'apps-transactions-purchase-payment-listImage', params: { id: item.id }}"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            <feather-icon
              icon="FileTextIcon"
              size="21"
            />
          </span>
        </b-link>
        </div>
        <div v-else>
          -
        </div>
      </template>

      <!-- Column: Account Type -->
      <template #cell(label)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.contact ? item.contact.label : 'Transfer' }}
        </span>
        <small class="font-italic"> {{ item.remark }} {{ $t('globalSingular.via') }}
          <span class="font-weight-bolder text-primary"> {{ companyActive.plan_id > 2 ? (item.office ? item.office.label : item.account.account_name) : item.account.account_name}} </span>
        </small>
      </template>

      <!-- Column: Amount -->
      <template #cell(amount)="{ item }">
        <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', (item.amount > 0) ? 'text-success' : 'text-danger' ]">
          {{ formatCurrency(item.amount) }}
        </span>
      </template>

      <!-- Column: Tags -->
      <template #cell(tags)="{ item }">
        <div v-if="(item.tags || []).length > 0" class="d-flex">
          <b-badge v-for="(tag, index) in item.tags" :key="index" pill variant="light-primary">{{ tag.name }}</b-badge>
        </div>
        <span v-else>-</span>
      </template>

      <!-- Column: Audit -->
      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.updated_process }} By {{ item.getUser.user_name }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('globalSingular.showing') }} {{ dataMeta.from }} {{ $t('globalSingular.to') }} {{ dataMeta.to }} {{ $t('globalSingular.of') }} {{ dataMeta.of }} {{ $t('globalSingular.entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>


<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge
} from 'bootstrap-vue'
import TableSearchColumn from "@/components/TableSearchColumn.vue"

import { formatCurrency } from '@/utils/formatter'
import useListTablePage from '@/comp-functions/useListTablePage'
// to access i18n
import useLocalization from '@/comp-functions/utils/useLocalization'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BBadge,
    TableSearchColumn
  },


  setup () {
    const { $t } = useLocalization()

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      searchData,
    } = useListTablePage({ url: 'transaction/purchase/payment' })

    // Table Handlers
    const tableColumns = ref([
      {
        key: 'code',
        label: $t('globalSingular.code'),
        search: { type: 'text', value: '' }
      },
      {
        key: 'image',
        label: $t('globalSingular.document'),
        thClass: 'text-center',
        tdClass: 'text-center',
        formatter: (value) => value || '-',
      },
      {
        key: 'date',
        label: $t('globalSingular.date'),
        sortable: true,
        search: { type: 'date', value: '' }
      },
      {
        key: 'office.label',
        label: $t('globalSingular.branch'),
        thStyle: { minWidth: '200px' },
        formatter: (value) => value || '-',
        search: {
          type: 'select',
          value: '',
          key: 'office_id',
          url: 'value/getBranch'
        }
      },
      {
        key: 'label',
        label: $t('globalSingular.description'),
        search: {
          type: 'text',
          value: '',
          key: 'description',
        }
      },
      {
        key: 'amount',
        label: $t('globalSingular.amount'),
        thClass: 'text-right',
        tdClass: 'text-right',
        search: { type: 'number', value: 0 }
      },
      {
        key: 'tags',
        label: $t('globalSingular.tags'),
        search: { type: 'text', value: '' }
      },
      {
        key: 'audit',
        label: $t('globalSingular.audit'),
        search: { type: 'text', value: '' }
      }
    ])


    return {
      formatCurrency,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      searchData,
    }
  },


  computed: {
    companyActive () {
      return this.$store.state.user.currentCompany
    },
  }
}
</script>
